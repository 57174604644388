import styles from "./style.module.css";

function ChannelSelector(props) {
  return (
    <div id={styles.channelSelector} className="list-group">
      <h6 className={styles.sideBarHeading}>Channels</h6>
      {props.channels.map((channel) => (
        <div key={channel}>
          <button
            style={{ textAlign: "left" }}
            className={
              "list-group-item list-group-item-action" +
              (props.currentChannel === channel ? " active" : "")
            }
            onClick={props.channelButtonCallback.bind(null, channel)}
          >
            {"#" + channel}
          </button>
        </div>
      ))}
    </div>
  );
}

export default ChannelSelector;
