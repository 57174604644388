import React from "react";
import styles from "./style.module.css";
class Message extends React.Component {
  constructor(props) {
    super(props);
    let stringArray = props.text.split(/(\s+)/);

    let textElements = [];
    let imageUrls = [];

    for (const token of stringArray) {
      if (isLink(token)) {
        textElements.push({ text: token, isLink: true });
        if (isImageLink(token)) {
          imageUrls.push(token);
        }
      } else {
        textElements.push({ text: token, isLink: false });
      }
    }

    this.state = {
      isImageOnly: textElements.length === 1 && imageUrls.length === 1,
      textElements: textElements,
      imageUrls: imageUrls,
      imageLoadedStates: new Array(imageUrls.length).fill(false),
    };
  }

  render() {
    return (
      <div>
        <p className={styles.textMessage}>
          {this.props.time} : {this.props.userName} :{" "}
          {!this.state.isImageOnly &&
            this.state.textElements.map((message, index) =>
              message.isLink ? (
                <a key={index} href={message.text}>
                  {message.text}
                </a>
              ) : (
                message.text
              )
            )}
        </p>
        {this.state.imageUrls.map((imageUrl, index) => (
          <img
            className={
              this.state.imageLoadedStates[index]
                ? styles.messageImage
                : styles.unLoadedImage
            }
            key={index}
            src={imageUrl}
            alt={imageUrl}
            onError={() => {
              this.setState({ isImageOnly: false });
            }}
            onLoad={() => {
              let newImageLoadedStates = [...this.state.imageLoadedStates];
              newImageLoadedStates[index] = true;
              this.setState({
                imageLoadedStates: newImageLoadedStates,
              });
              // Delay the calling of this function, so that the style can propogate to the image first.
              setImmediate(this.props.imageLoadedCallback);
            }}
          ></img>
        ))}
      </div>
    );
  }
}

function isLink(link) {
  let url;

  try {
    url = new URL(link);
  } catch {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

function isImageLink(link) {
  let imagePattern = new RegExp(".*(jpeg|jpg|gif|png)($|/?.*)");
  return !!imagePattern.test(link);
}

export default Message;
