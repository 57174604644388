import React from "react";
import styles from "./style.module.css";

class LoginForm extends React.Component {
  handleChange(event) {
    this.setState({ signInForm: event.target.value });
  }

  handleSignIn(event) {
    this.props.signInCallback(this.state.signInForm);
    event.preventDefault();
  }

  constructor(props) {
    super(props);
    this.state = {
      signInForm: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
  }

  render() {
    return (
      <form className={styles.form} onSubmit={this.handleSignIn}>
        <label className="sr-only"></label>
        <input
          className="form-control form-control-lg"
          type="nickname"
          value={this.state.signInForm}
          onChange={this.handleChange}
          placeholder="Nickname"
        />
        <input
          className="btn btn-primary btn-lg btn-block"
          type="submit"
          value="Chat"
        />
      </form>
    );
  }
}

export default LoginForm;
