import React from "react";
import LoginForm from "./LoginForm/LoginForm";
import ChatBox from "./ChatBox/ChatBox";
import Messages from "./Messages/Messages";
import ChannelSelector from "./ChannelSelector/ChannelSelector";
import axios from "axios";
import styles from "./style.module.css";
const backend_url = "https://talk.hermenault.dev/messages";
// const backend_url = "http://localhost:8081/messages";

class App extends React.Component {
  signInCallback(userName) {
    // Connect to the server event source
    const eventSource = new EventSource(backend_url);
    this.setState({ userID: userName, appState: "SIGNED_IN" });
    eventSource.onmessage = (event) => {
      const structuredMessage = JSON.parse(event.data);
      if (structuredMessage.type === "channel") {
        // Add the channel to the messages store
        this.channelMessages[structuredMessage.channel] = [];
        this.setState((state) => {
          if (state.channels.length === 0) {
            return {
              channels: [...this.state.channels, structuredMessage.channel],
              currentChannel: structuredMessage.channel,
            };
          }

          return {
            channels: [...this.state.channels, structuredMessage.channel],
          };
        });
      }
      if (structuredMessage.type === "message") {
        this.channelMessages[structuredMessage.channel].push(structuredMessage);
        if (this.state.currentChannel === structuredMessage.channel) {
          this.setState({
            messages: [...this.state.messages, structuredMessage],
          });
        }
      }
    };
    eventSource.onerror = () => {
      this.setState({
        messages: ["An error occured, refresh to reconnect."],
      });
      eventSource.close();
    };
  }

  messageSendCallback(message) {
    axios
      .post(backend_url, {
        text: message,
        userName: this.state.userID,
        channel: this.state.currentChannel,
      })
      .catch(() => {
        alert("Message too long!");
      });
  }

  channelButtonCallback(channel) {
    // Set the messages displayed to the current channel
    this.setState({
      currentChannel: channel,
      messages: [...this.channelMessages[channel]],
    });
  }
  componentDidMount() {
    // Initialize a store for all the messages by channel.
    this.channelMessages = {};
  }
  constructor(props) {
    super(props);

    this.state = {
      messages: [],
      channels: [],
      currentChannel: null,
      appState: "SIGN_IN",
      messageForm: "",
    };
    this.signInCallback = this.signInCallback.bind(this);
    this.messageSendCallback = this.messageSendCallback.bind(this);
    this.channelButtonCallback = this.channelButtonCallback.bind(this);
  }

  render() {
    return (
      <div>
        {this.state.appState === "SIGN_IN" && (
          <LoginForm signInCallback={this.signInCallback} />
        )}
        {this.state.appState === "SIGNED_IN" && (
          <div className={styles.applicationPane}>
            <ChannelSelector
              channels={this.state.channels}
              currentChannel={this.state.currentChannel}
              channelButtonCallback={this.channelButtonCallback}
            />
            <div className={styles.messagingSection}>
              <Messages messages={this.state.messages} />
              <ChatBox messageSendCallback={this.messageSendCallback} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default App;
