import React from "react";
import styles from "./style.module.css";
class ChatBox extends React.Component {
  handleChange(event) {
    this.setState({ messageForm: event.target.value });
  }

  handleSignIn(event) {
    this.props.messageSendCallback(this.state.messageForm);
    this.setState({ messageForm: "" });
    event.preventDefault();
  }

  constructor(props) {
    super(props);
    this.state = {
      messageForm: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
  }

  render() {
    return (
      <div className={styles.chatBoxContainer}>
        <form onSubmit={this.handleSignIn} autoComplete="off">
          <input
            type="text"
            value={this.state.messageForm}
            onChange={this.handleChange}
            className="form-control"
          />
        </form>
      </div>
    );
  }
}

export default ChatBox;
