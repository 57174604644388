import Message from "../Message/Message";
import styles from "./style.module.css";
import { useEffect, useRef } from "react";

function Messages(props) {
  const bottomOfMessages = useRef(null);
  // Scroll to the bottom of the messages whenever a new message is posted.
  useEffect(() => {
    bottomOfMessages.current.scrollIntoView();
  });

  return (
    <div className={styles.messageBox}>
      <div className={styles.messages}>
        {props.messages
          .slice(0)
          .reverse()
          .map((message) => (
            <Message
              key={message.user + message.text + message.time}
              userName={message.userName}
              text={message.text}
              time={message.time}
              imageLoadedCallback={() => {
                bottomOfMessages.current.scrollIntoView();
              }}
            ></Message>
          ))}
      </div>
      <div ref={bottomOfMessages} />
    </div>
  );
}

export default Messages;
